import styled from "styled-components";

export const StyledButton = styled("button")<{ color?: string }>`
  background: ${(p) => p.color || "#fff"};
  color: ${(p) => (p.color ? "#000" : "#000")};
  font-size: 1rem;
  font-weight: 700;
  border-radius: 100px;
  padding: 9px 30px;
  cursor: pointer;
  margin-top: 0.625rem;
  transition: all 0.2s ease-in-out;

  &:hover,
  &:active,
  &:focus {
    color: #fff;
    background-color: #27aeef;
  }

  &:active {
    filter: brightness(0.8);
  }
`;
